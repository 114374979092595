import 'select2/dist/js/select2';
import 'owl.carousel';
import 'jquery-zoom';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import * as Toastr from 'toastr';

import Vue from 'vue/dist/vue.esm';
import JsonApi from '../../vendor/json-api';
import Calendar from '../components/calendar/widget';
import ProductsVue from '../components/products/products';
import GtmEvents from '../gtm_events';

export default class Products {
  index() {
    $('form.product_search select').select2();
    $('form.product_search select').on('change', function() {
      $(this)
        .closest('form')
        .submit();
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    $.ajax({
      method: 'GET',
      url: '/get-products',
      data: {
        page: urlParams.get('page'),
        term: urlParams.get('term'),
      },
      success: (data) => {
        $('.total-pages').text(Math.ceil(data.products_count / 10));
        if (
          $('#current-page').text() ==
          $('.total-pages')
            .first()
            .text()
        ) {
          $('#next-page').css('pointer-events', 'none');
        }
      },
    });

    new Vue({
      el: '#products-wrapper',
      components: { ProductsVue },
    });
  }

  category() {
    this.index();
  }

  show() {
    // $(function() {
    //   $('.readmore-btn').on('click', function() {
    //     $('.description').toggleClass('truncate');
    //     let text = $('.readmore-btn > a').text();

    //     if (text == 'Read more') {
    //       $('.readmore-btn > a').text('Show less');
    //     } else {
    //       $('.readmore-btn > a').text('Read more');
    //     }
    //   });
    // });
    
    GtmEvents.trackItemEvent('view_item', { product_data: gon.product.data })

    $('.calendar-toggle').on('click', () => {
      const calendar = $('.calendar-popup');
      const overlay = $('.calendar-overlay-close');

      if (calendar.css('opacity') === '0') {
        calendar.css('opacity', '1');
        calendar.css('visibility', 'visible');
        calendar.css('pointer-events', 'all');
        overlay.css('opacity', '1');
        overlay.css('visibility', 'visible');
      }
    });

    $('.slick-carousel-images').slick({
      dots: false,
      nextArrow: false,
      prevArrow: false,
      cssEase: 'linear',
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            dots: true,
            vertical: false,
            verticalSwiping: false,
          },
        },
      ],
    });

    $('.slick-carousel-images-mobile').slick({
      dots: true,
      nextArrow: false,
      prevArrow: false,
      cssEase: 'linear',
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    });

    $('.owl-carousel-products').owlCarousel({
      loop: false,
      items: 2,
      margin: 10,
      nav: false,
      dots: false,
      responsive: {
        900: {
          items: 4,
          margin: 30,
        },
      },
    });

    const $status = $('.page-numbers');
    const $slickElement = $('.reviews-carousel-two');

    $slickElement.on('init reInit afterChange', function(
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      const i = (currentSlide ? currentSlide : 0) + 1;
      slick.slideCount == 0
        ? $status.text('')
        : $status.text(i + ' / ' + slick.slideCount);
    });

    $('.reviews-carousel-two').slick({
      nav: true,
      dots: false,
      nextArrow: '.next-arrow',
      prevArrow: '.prev-arrow',
      cssEase: 'linear',
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 2,
    });

    $('.slick-carousel-images .item').on('click', function() {
      const $this = $(this);
      if ($this.data('video-url')) {
        const videoUrl = $this.data('video-url');
        const videoIframe = getVideoIframe(videoUrl);
        $('.product-container .preview .img-wrapper').html(videoIframe);
      } else if ($this.data('square-path')) {
        const imagePath = $this.data('square-path');
        $('.product-container .preview .img-wrapper').html(`<img src="${imagePath}" alt="Product Image">`);
      }
    });

    $(document).ready(function() {
      const firstItem = $('.slick-carousel-images .item').first();
      if (firstItem.data('video-url')) {
        const videoUrl = firstItem.data('video-url');
        const videoIframe = getVideoIframe(videoUrl);
        $('.product-container .preview .img-wrapper').html(videoIframe);
      } else if (firstItem.data('square-path')) {
        const imagePath = firstItem.data('square-path');
        $('.product-container .preview .img-wrapper').html(`<img src="${imagePath}" alt="Product Image">`);
      }
    });

    function extractYouTubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11 ? match[2] : null;
    }

    function extractVimeoId(url) {
      const regExp = /vimeo.*\/(\d+)/i;
      const match = url.match(regExp);
      return match ? match[1] : null;
    }

    function getVideoIframe(videoUrl) {
      let iframeHtml = '';
      if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
        const videoId = extractYouTubeId(videoUrl);
        iframeHtml = `<iframe width='100%' height='100%' src='https://www.youtube.com/embed/${videoId}?controls=1' title='YouTube video player' frameborder='0' allowfullscreen></iframe>`;
      } else if (videoUrl.includes('vimeo.com')) {
        const videoId = extractVimeoId(videoUrl);
        iframeHtml = `<iframe width='100%' height='100%' src='https://player.vimeo.com/video/${videoId}' title='Vimeo video player' frameborder='0' allowfullscreen></iframe>`;
      }
      return iframeHtml;
    }

    if ($('#calendar-app').length != '0') {
      new Vue({
        el: '#calendar-app',
        components: { Calendar },
        data: {
          calendar: gon.calendar,
          order: gon.order,
          product: JsonApi.deserialize(gon.product),
          shopping_mode: gon.shopping_mode,
          account_type: gon.account_type,
        },
        methods: {
          closeCalendar: this.closeCalendar,
          handleEnsureAccount(data) {
            this.calendar = data.calendar;
            this.order = data.order;
            this.product = JsonApi.deserialize(data.product);
            this.account_type = 'User';
          }
        },
      });
    }

    $('.flatpickr-calendar').prependTo('.calendar-popup');

    $('#calendar-close, .calendar-overlay-close').on('click', () => {
      this.closeCalendar();
    });

    $('.production-description-full-cta').on('click', function() {
      $('.product-description-short').toggleClass('hidden');
    });
  }

  closeCalendar() {
    const calendar = $('.calendar-popup');
    const overlay = $('.calendar-overlay-close');

    calendar.css('opacity', '0');
    calendar.css('visibility', 'hidden');
    calendar.css('pointer-events', 'none');
    overlay.css('opacity', '0');
    overlay.css('visibility', 'hidden');
  }
}
