<template>
  <transition name="slide-fade" v-on:after-leave="afterLeave">
    <div class="col-3" v-if="show">
      <div class="p-1 border border-light my-2 image-wrapper">
        <div class="icon-wrapper border" v-on:click="removeItem">
          <i class="icon fas fa-times"></i>
        </div>

        <div v-if="isImage">
          <img :src="item.variants.square_thumb" alt="Image preview">
        </div>
        <div v-else-if="isVideo">
          <iframe
            :src="generateIframeSrc(item.video_url)"
            width="100%"
            height="100%"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        <div v-else>
          <p>No image or video available.</p>
        </div>

        <div class="form-group string">
          <label class="form-control-label string">Alt text</label>
          <input class="form-control string" type="text" @change="update" v-model="item.alt_text">
        </div>

        <div class="form-group string">
          <label class="form-control-label string">Video URL</label>
          <input class="form-control string" type="text" @change="update" v-model="item.video_url">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import * as Toastr from 'toastr';

export default {
  props: {
    item: {type: Object, required: true}
  },
  data: function () {
    return {
      show: true,
    };
  },
  computed: {
    isImage() {
      return this.item.variants && this.item.variants.square_thumb && !this.item.video_url;
    },
    isVideo() {
      return !!this.item.video_url;
    },
  },
  methods: {
    removeItem: function() {
      var that = this;
      var images = this.$parent.images;

      $.ajax({
        method: 'DELETE',
        url: that.item.links.self,
        success: function(res) {
          that.show = false
          Toastr.success('Media removed!');
        },
        error: function(res) {
          Toastr.error(res.responseJSON.errors_full.join(), 'Error removing media.');
        }
      })
    },
    afterLeave: function() {
      var images = this.$parent.realList;
      images.splice(images.indexOf(this.item), 1);
    },
    update() {
      $.ajax({
        method: 'PUT',
        url: this.item.links.self,
        data: { product_image: { alt_text: this.item.alt_text, video_url: this.item.video_url } },
        success: () => {
          Toastr.success('Media item updated!');
        },
        error: (res) => {
          Toastr.error(res.responseJSON.errors_full.join(), 'Error updating media item.');
        },
      });
    },
    generateIframeSrc(url) {
      if (url.includes("youtube.com") || url.includes("youtu.be")) {
        const videoId = this.extractYouTubeId(url);
        return `https://www.youtube.com/embed/${videoId}`;
      } else if (url.includes("vimeo.com")) {
        const videoId = this.extractVimeoId(url);
        return `https://player.vimeo.com/video/${videoId}`;
      }
      return url;
    },
    extractYouTubeId(url) {
      try {
        const urlObj = new URL(url);
        if (urlObj.hostname.includes("youtu.be")) {
          return urlObj.pathname.slice(1);
        }
        return urlObj.searchParams.get("v");
      } catch {
        return '';
      }
    },
    extractVimeoId(url) {
      try {
        const urlObj = new URL(url);
        return urlObj.pathname.split("/").pop();
      } catch {
        return '';
      }
    },
  },
};
</script>

<style lang="css">
.image-wrapper {
  text-align: center;
  position: relative;
}

.image-wrapper img {
  max-width: 100%;
  width: auto;
}

.image-wrapper .icon-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #fff;
  cursor: pointer;
  width: 22px;
}

</style>
