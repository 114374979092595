<template>
  <div>
    <div class="product-page">
      <div v-if="allJewelryVisible" class="hero-wrapper hero-banner-products pb-0">
        <div class="hero"></div>
        <div class="container">
          <div class="stripes"></div>
          <div class="description description-alt">
            <div class="desc">
              <div class="focus-title my-10">
                <h1 class="main-text mb-5">RENT FINE JEWELRY</h1>
                <p class="sub-text">
                  Shine with our exclusive jewelry to rent. Explore our extensive collection of fine jewelry, crafted with superior metals, diamonds, and gemstones.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <template v-if="!hasTerm && allJewelryVisible">
        <FocusedList 
          :products="productsNewArrivals"
          :isUser="isUser"
          @favoriteHandler="favoriteHandler"
        >

        <template v-slot:title>
          <div class="focus-title my-10">
            <h1 class="main-text mb-5 text-left">
              New <span class="text-orange">Arrivals</span>
            </h1>
            <p class="sub-text text-left">
              Rent our exquisite and most recently launched collection of fine jewelry.
            </p>
          </div>
        </template>

        </FocusedList>

        <Categories class="my-60" />

        <FocusedList 
          :products="productsFavorites"
          :isUser="isUser"
          @favoriteHandler="favoriteHandler"
          class="my-60"
        >
          <template v-slot:title>
            <div class="focus-title my-10">
              <h1 class="main-text mb-5 text-left">
                Most <span class="text-orange">Favorites</span>
              </h1>
              <p class="sub-text text-left">
                Rent our stunning collection of most loved and most popular fine jewelry.
              </p>
            </div>
          </template>
        </FocusedList>

        <div class="container">
          <div class="focus-title my-10">
            <h2 class="main-text mb-5 text-left">
              Rent By Occasion
            </h2>
          </div>

          <div class="row occassions-banners-wrapper my-30">
            <div class="col-md-6">
              <div class="occasion-banner occasion-banner--wedding mb-30">
                <a href="/rent-jewelry?occasion=wedding">
                  <img src="~images/app/products/index/occassions/wedding.webp" />
                  <h3>WEDDING</h3>
                </a>
              </div>

              <div class="occasion-banner occasion-banner--professional mb-30">
                <a href="/rent-jewelry?occasion=professional">
                  <img src="~images/app/products/index/occassions/professional.webp" />
                  <h3>PROFESSIONAL</h3>
                </a>
              </div>
            </div>

            <div class="col-md-6">
              <div class="occasion-banner occasion-banner--date-night mb-30">
                <a href="/rent-jewelry?occasion=date-night">
                  <img src="~images/app/products/index/occassions/date-night.webp" />
                  <h3>DATE NIGHT</h3>
                </a>
              </div>

              <div class="occasion-banner occasion-banner--everyday mb-30">
                <a href="/rent-jewelry?occasion=everyday">
                  <img src="~images/app/products/index/occassions/everyday.webp" />
                  <h3>Everyday</h3>
                </a>
              </div>

              <a href="/occasions" class="occasion-banner occasion-banner--all">
                <h3>SEE ALL OCCASIONS</h3>
              </a>
            </div>
          </div>
        </div>
      </template>

      <template v-if="kindByTypeVisible || kindByOccasionVisible || kindByCollectionVisible">
        <Hero 
          :filterKind="filterKind"
          :filterValue="filterValue"
          :mainText="loadMainText" 
          :subText="loadSubText" 
        />
        <TypeStyle v-if="kindByTypeVisible" :type="type" />
      </template>

      <div v-if="isCollection && leadingTextVisible">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="focus-title">
              <h2 class="main-text mb-5 text-center">
                {{ gonCollection.leading_title }}
              </h2>

              <p class="sub-text text-center">
                {{ gonCollection.leading_text }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="productsVisible" class="products-wrapper">
        <div class="navigation">
          <div class="nav-box" v-if="collection === 'all'">
            <a href="/">Home</a>
            <span>/</span>
            <a href="/rent-jewelry">Jewelry</a>
            <span>/</span>
            <a>{{ type[0].toUpperCase() + type.slice(1) }}</a>
          </div>
        </div>
        
        <div id="main-container" :class="{ 'diamonds-section': collection == 'diamond-jewelry-for-rent' }">
          <div class="filter-menu" v-if="collection === 'all'">
            <h4
              @click="isAccordionsVisible = !isAccordionsVisible"
              id="menu-heading"
            >
              Filter by
            </h4>
            <div
              class="accordions"
              v-bind:style="{ top: isAccordionsVisible ? '0' : '-120vh' }"
            >
              <svg
                id="#close-filter"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                @click="isAccordionsVisible = false"
              >
                <line x1="0" y1="0" x2="50" y2="50" />
                <line x1="50" y1="0" x2="0" y2="50" />
              </svg>
              <div
                v-for="panel in panelData"
                :key="panel.id"
                class="accordion"
                :id="panel.id"
              >
                <div class="panel panel-default">
                  <div
                    class="ac-title collapsed"
                    data-toggle="collapse"
                    :data-parent="`#` + panel.id"
                    :href="`#` + panel.href"
                  >
                    {{ panel.panelName }}
                  </div>
                </div>
                <ul class="panel-collapse collapse in" :id="panel.href">
                  <div class="filter-container">
                    <li
                      v-for="filterOption in panel.data"
                      :key="filterOption.id"
                    >
                      <input
                        type="radio"
                        :id="filterOption.id"
                        :name="panel.panelName.toLowerCase()"
                        v-model="$data[panel.panelName.toLowerCase()]"
                        :value="filterOption.text.toLowerCase()"
                        v-on:change="getProducts(true)"
                      />
                      <label :for="filterOption.id">
                        <p>{{ filterOption.text }}</p>
                        <img v-if="panel.panelName.toLowerCase() == 'colors'"
                          :class="['red', 'pink', 'yellow', 'orange'].includes(filterOption.id.split('-')[1]) ? 'img-saturated' : 
                                  ['black'].includes(filterOption.id.split('-')[1]) ? 'img-darker' :
                                  ['purple'].includes(filterOption.id.split('-')[1]) ? 'img-saturated-hue' : ''"
                          :src="colorsImagesLinks[filterOption.text.toLowerCase()]"/>
                      </label>
                    </li>
                  </div>
                </ul>
              </div>
              <div class="spacer" />
              <button id="clear-all" @click="clearAll()">Clear All</button>
              <div class="spacer" />
            </div>
          </div>
          <div class="products-container">
            <ProductItem
              v-for="(p, i) in itemsWithBanner"
              :key="i"
              :product="p"
              :index="i"
              :isUser="isUser"
              @favoriteHandler="favoriteHandler"
            />
          </div>
        </div>
        
        <nav>
          <div class="pagination-products" id="bottom-pagination">
            <div class="chevron left">
              <a @click="previousPage()"></a>
            </div>
            &nbsp;
            <span>
              <span id="current-page"> {{ page }}</span>
              <span>&nbsp;/&nbsp;</span>
              <span id="total-pages">{{
                totalPages === 0 ? 1 : totalPages
              }}</span>
              &nbsp;
            </span>
            <div class="chevron">
              <a @click="nextPage()"></a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import { panelData } from "../../../../data/productsData.js";
import * as Toastr from "toastr";
import ProductItem from "./item.vue";
import FocusedList from "./focused_list.vue";
import Categories from "./categories.vue";
import Hero from "./hero.vue";
import TypeStyle from "./type_style.vue";
import _ from "lodash";
import JsonApi from "../../../vendor/json-api"

export default {
  inherit: true,
  components: {
    ProductItem,
    FocusedList,
    Categories,
    Hero,
    TypeStyle
  },
  props: {
    filter: { type: String },
    colorsImagesLinks: { type: Object },
  },
  data() {
    return {
      products: [],
      banners: [],
      productsNewArrivals: [],
      productsFavorites: [],
      plan: this.$props.filter,
      type: "all jewelry",
      occasion: "all",
      collection: "all",
      collectionName: "",
      material: "all",
      gemstones: "all",
      style: "all",
      colors: "all",
      // colorsImagesLinks: this.$props.colorsImagesLinks, // disabled because of warning
      isUser: false,
      panelData: panelData,
      isAccordionsVisible: false,
      page: 1,
      totalPages: 1,
      foundProductsCount: 1,
      per_page_variable: 9,
      isLoading: false,
      isFirstRender: true,
      heroTexts: {
        type: {
          bracelets: {
            mainText: "Rent Bracelets By Style",
            subText: "Borrow bold and dazzling bracelets to make a statement at your next special occasion."
          },
          rings: {
            mainText: "Rent Designer Rings",
            subText: "Level up your style with designer rings to rent. Pick your favorite from a variety of styles."
          },
          necklaces: {
            mainText: "Rent Designer Necklaces",
            subText: "Transform your look with our selection of fine designer necklaces to borrow."
          },
          earrings: {
            mainText: "Rent Designer Earrings",
            subText: "As a staple to your outfit, borrow exquisite earrings that portray your true essence."
          }
        },
        occasion: {
          wedding: {
            mainText: "Rent Wedding Jewelry",
            subText: "Borrow bold and dazzling wedding jewelry to make a statement at your special day."
          },
          "professional/work": {
            mainText: "Rent Professional Jewelry",
            subText: "Borrow bold and dazzling professional jewelry to make a statement at your next special occasion."
          },
          "night out": {
            mainText: "Rent Date Night Jewelry",
            subText: "Borrow bold and dazzling date night jewelry to make a statement at your next special occasion."
          },
          everyday: {
            mainText: "Rent Everyday Jewelry",
            subText: "Borrow bold and dazzling everyday jewelry to make a statement at your next special occasion."
          },
          birthday: {
            mainText: "Rent Birthday Jewelry",
            subText: "Borrow bold and dazzling birthday jewelry to make a statement at your next special occasion."
          }
        },
        collection: {
          'featured-designer-jane-gordon-jewelry-for-rent': {
            mainText: "Rent Jane Gordon Jewelry",
            subText: "Borrow bold and dazzling Jane Gordon jewelry to make a statement at your next special occasion."
          },
          'couture-jewelry-for-rent': {
            mainText: "Rent Couture Jewelry",
            subText: "Borrow bold and dazzling couture jewelry to make a statement at your next special occasion."
          },
          'diamond-jewelry-for-rent': {
            mainText: "Rent Diamond Jewelry",
            subText: "Borrow bold and dazzling diamond jewelry to make a statement at your next special occasion."
          },
          'designer-hoop-earrings-for-rent': {
            mainText: "Rent Hoop Earrings",
            subText: "Borrow bold and dazzling hoop earrings to make a statement at your next special occasion."
          },
          'color-cocktail-rings-for-rent': {
            mainText: "Rent Cocktail Rings",
            subText: "Borrow bold and dazzling cocktail rings to make a statement at your next special occasion."
          },
        }
      }
    };
  },
  computed: {
    bannerPlanId: function () {
      return this.plan === "rbtd" ? "rbtd-plan" : "sub-plan";
    },
    allJewelryVisible() {
      return this.type === "all jewelry" && this.collection === "all" && this.occasion === "all";
    },
    kindByTypeVisible() {
      return this.type !== "all jewelry";
    },
    kindByOccasionVisible() {
      return this.occasion !== "all";
    },
    kindByCollectionVisible() {
      return this.collection !== "all";
    },
    itemsWithBanner() {
      if (this.banners.length === 0) return this.products;
      
      const result = [];
      this.products.forEach((prod, index) => {
        result.push(prod);

        if (index === 3 && this.banners[0]) {
          result.push({ isBanner: true, ...this.banners[0] })
        }
        if (index === 11 && this.banners[1]) {
          result.push({ isBanner: true, ...this.banners[1] })
        }
      });

      return result;
    },
    term() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get("term")
    },
    hasTerm() {
      return this.term != '' && this.term != null
    },
    productsVisible() {
      if (this.hasTerm) {
        return true;
      }

      return this.type !== "all jewelry" || this.collection !== "all" || this.occasion !== "all";
    },
    filterKind() {
      if (this.kindByTypeVisible) {
        return "type";
      } else if (this.kindByOccasionVisible) {
        return "occasion";
      } else if (this.kindByCollectionVisible) {
        return "collection";
      }
    },
    filterValue() {
      if (this.kindByTypeVisible) {
        return this.type;
      } else if (this.kindByOccasionVisible) {
        return this.occasion;
      } else if (this.kindByCollectionVisible) {
        return this.collection;
      }
    },
    loadHeroText() {
      return this.heroTexts[this.filterKind.toLocaleLowerCase()][this.filterValue.toLocaleLowerCase()];
    },
    loadMainText() {
      let text = this.loadHeroText?.mainText;

      return text ? text : "Rent Jewelry";
    },
    loadSubText() {
      let text = this.loadHeroText?.subText;

      return text ? text : "Borrow bold and dazzling jewelry to make a statement at your next special occasion.";
    },
    gonCollection() {
      if (gon.collection) {
        return JsonApi.deserialize(gon.collection);
      }

      return undefined
    },
    isCollection() {
      return !_.isEmpty(this.gonCollection)
    },
    leadingTextVisible() {
      return this.isCollection && !_.isEmpty(this.gonCollection.leading_text)
    },
  },
  methods: {
    nextPage: function () {
      if (
        this.page ===
          Math.ceil(this.foundProductsCount / this.per_page_variable) ||
        this.isLoading ||
        this.foundProductsCount === 0
      ) {
        return;
      }
      this.page = this.page + 1;
      this.getProducts();
      this.getBanners();
    },
    previousPage: function () {
      if (this.page <= 1 || this.isLoading) {
        return;
      }
      this.page = this.page - 1;
      this.getProducts();
      this.getBanners();
    },
    getProducts: function (isFilter) {
      if (isFilter) {
        this.page = 1;
      }
      this.isLoading = true;

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (this.isFirstRender) {
        urlParams.get("type") ? (this.type = urlParams.get("type")) : null;
        urlParams.get("collection")
          ? (this.collection = urlParams.get("collection"))
          : null;
        urlParams.get("occasion")
          ? (this.occasion = urlParams.get("occasion"))
          : null;
        urlParams.get("plan") ? (this.plan = urlParams.get("plan")) : "rbtd";
        urlParams.get("colors") ? (this.colors = urlParams.get("colors")) : null;

        this.occasion === "professional"
          ? (this.occasion = "professional/work")
          : null;
        this.occasion === "date-night" ? (this.occasion = "night out") : null;

        this.isFirstRender = false;
      }

      urlParams.get("style")
          ? (this.style = urlParams.get("style"))
          : null;

      if (window !== undefined) {
        window.innerWidth < 1131
          ? (this.per_page_variable = 12)
          : (this.per_page_variable = 12);

        $.ajax({
          method: "GET",
          url: "/get-products",
          data: {
            per_page: this.per_page_variable,
            page: this.page,
            term: this.term,
            plan: this.plan,
            type: this.type,
            occasion: this.occasion,
            collections_slugs: this.collection,
            material: this.material,
            gemstones: this.gemstones,
            style: this.style,
            colors: this.colors,
          },
          success: (data) => {
            this.products = [];
            this.products = [...data.products];
            this.foundProductsCount = data.products_count;
            this.collectionName = data.collection_name;

            this.isUser = data.is_user;
            this.products.forEach((el) => {
              el.rental_price.cents = (el.rental_price.cents / 100).toFixed(2);
              el.market_price.cents = (el.market_price.cents / 100).toFixed(2);
              el.fav_href = "/favorites/" + el.slug;
            });
            this.isLoading = false;
            this.totalPages = Math.ceil(
              this.foundProductsCount / this.per_page_variable
            );
          },
          error: function (res) {
            Toastr.error(res.statusText, "Error fetching products.");
            this.isLoading = false;
          },
        });
      }
    },
    getBannersParams() {
      if (this.kindByCollectionVisible) {
        return `kind=collection&slug=${this.collection}`;
      }

      if (this.kindByOccasionVisible) {
        return `kind=occasion&slug=${this.occasion}`;
      }

      // type == categories
      if (this.kindByTypeVisible) {
        return `kind=category&slug=${this.type}`;
      }

      return '';
    },
    getBanners() {
      if (this.getBannersParams === '') {
        return
      }

      $.ajax({
        method: "GET",
        url: `/product_banners?page=${this.page}&${this.getBannersParams()}`,
        success: (response) => {
          this.banners = JsonApi.deserialize(response).data;
        },
        error: (err) => {
          console.error("Error fetching banners:", err);
        }
      });
    },
    getProductsNewArrivals() {
      this.getProductsByCollection('new-arrivals-carousel-rent-jewelry-page-top', 'productsNewArrivals');
    },
    async getProductsFavorites() {
      this.getProductsByCollection('for-carousel-favorites', 'productsFavorites');
    },
    getProductsByCollection(collectionSlug, dataKey) {
      try {
        this.loading = true;
        $.ajax({
          method: "GET",
          url: `/get-products?collections_slugs=${collectionSlug}`,
          success: (data) => {
            this[dataKey] = data.products
            
            this.isUser = data.is_user;
            this[dataKey].forEach((el) => {
              el.rental_price.cents = (el.rental_price.cents / 100).toFixed(2);
              el.market_price.cents = (el.market_price.cents / 100).toFixed(2);
              el.fav_href = "/favorites/" + el.slug;
            });

            return this[dataKey];
          },
          error: function (res) {
            Toastr.error(res.statusText, "Error fetching products.");
          },
        });
      } catch (e) {
        Toastr.error("Error fetching new arriaval products");
      }
    },
    favoriteHandler: (product) => {
      product.favourite = !product.favourite;
    },
    clearAll: function () {
      this.plan = "rbtd";
      this.type = "all jewelry";
      this.occasion = "all";
      this.material = "all";
      this.gemstones = "all";
      this.style = "all";
      this.colors = "all";
      this.page = 1;
      this.getProducts();
    }
  },
  mounted() {
    this.getProducts();
    this.getBanners(); 
    this.getProductsNewArrivals();
    this.getProductsFavorites();
  },
};
</script>
