import Vue from "vue/dist/vue.esm";
import JsonApi from "../../vendor/json-api";
import * as Toastr from 'toastr';
import draggable from 'vuedraggable';

import Dropzone from "../../components/images/dropzone";
import ImageItem from "../../components/images/item";

export default class AdminProductsImages {
  index() {
    var app = new Vue({
      el: '#product-images-app',
      components: { ImageItem, Dropzone, draggable },
      data: {
        images: JsonApi.deserialize(gon.payload).data,
        newVideoUrl: '',
      },
      methods: {
        onEnd: function(event) {
          var positions = [];
          $.each(this.images, function( index, value ) {
            value.position = index
            positions.push({id: value.id, position: index});
          });

          $.ajax({
            method: 'POST',
            url: $(this.$el).data('positions-path'),
            data: {
              positions: positions
            },
            success: function(res) {
              Toastr.success('Positions saved!');
            },
            error: function(res) {
              Toastr.error('Error saving positions.');
            }
          })
        },
        addVideoUrl() {
          if (this.newVideoUrl.trim() === '') {
            Toastr.error("Video URL cannot be empty.");
            return;
          }

          const productPath = this.$el.dataset.productPath;
          const formData = new FormData();

          formData.append('product_image[video_url]', this.newVideoUrl.trim());

          $.ajax({
            method: "POST",
            url: `${productPath}/images.json`,
            data: formData,
            processData: false,
            contentType: false,
            success: (res) => {
              this.images.push(res);
              this.newVideoUrl = '';
              Toastr.success("Video URL added successfully!");
            },
            error: (res) => {
              Toastr.error(res.responseJSON?.errors_full?.join(", ") || "Error adding video URL.");
            },
          });
        },
        onFileUploaded(file, response) {
          // Handle the uploaded image
          this.images.push(response);
          Toastr.success('Image uploaded successfully!');
        },
      },
    });
  }
}